<template>
  <div>
    <header :class="{ 'header-small': isScrolled, 'mobile-header': isMobile, 'hidden-header': isScrolled }">
      <div class="header-content">
        <h1>Welcome to My Site</h1>
        <nav class="navbar" :class="{ 'navbar-scrolled': isScrolled }">
          <ul>
            <li><a href="#home" @click.prevent="scrollToSection('home')">Home</a></li>
            <li><a href="#about" @click.prevent="scrollToSection('about')">About</a></li>
            <li><a href="/ShengyiCV-4.6.pdf" target="_blank" rel="noopener noreferrer">CV</a></li>
            <li><a href="#contact" @click.prevent="scrollToSection('contact')">Contact</a></li>
          </ul>
        </nav>
      </div>
    </header>

    <div class="container" :class="{ 'scrolled': isScrolled }">
      <section id="home" class="profile">
        <img src="@/assets/ID.jpg" alt="Profile Image" class="profile-image">
        <div id="contact" class="bio">
          <h2>Shen Zhang</h2>
          <p>A software engineer who is looking for a job.</p>
          <button @click="toggleContactSection">Contact Me</button>
        </div>
      </section>

      <transition name="fade">
        <div class="hidden-section" v-if="showContactSection">
          <h3>Contact Information</h3>
          <p>Email: shen.zhang0907@gmail.com</p>
          <p>Linkedin: <a href="https://www.linkedin.com/in/shen-zhang-b889002b3/" target="_blank">https://www.linkedin.com/in/shen-zhang-b889002b3/</a></p>
      </div>
      </transition>

      <section id="about" class="content">
        <div class="content-item">
          <h4>About myself</h4>
          <p>Hello! I'm Shengyi Zhang, a recent graduate with a Bachelor of Science in Computer Science from the University of Kentucky. 
            My journey into the world of technology began in high school when I took my first programming class. 
            This sparked a passion for coding that led me to pursue a degree in computer science.
          </p>
          <p>
            Throughout my academic career, I have developed a strong foundation in various programming languages and technologies, including Python, Java, C, C++, JavaScript, HTML, CSS, Bootstrap, React, Node.js, Vue, and jQuery. 
            I am also proficient in data analysis and management tools such as Pandas, NumPy, Scikit-learn, R, and MySQL.
          </p>
          <p>
            I have gained practical experience through internships and projects, such as working as a Computer Science Research Analyst Intern at the University of Kentucky Health Care. 
            During this time, I Analyzed genomic data to identify variant allele frequencies, comparing local patient data to national databases for insights into population health trends, 
            and applied web development skills to make our database visible via a website, enhancing accessibility and usability for researchers and healthcare professionals. (Link to the project: <a href="https://variant-db.pml.uky.edu" target="blank">https://variant-db.pml.uky.edu</a>)
          </p>
          <p>
            I also have experience as a Software Specialist at Signal Solutions LLC, where I developed programs to assess sleep, wake, 
            and breathing variables from piezoelectric signals in mouse models of Alzheimer's Disease and Substance Use Disorders. 
          </p>
          <p>
            One of my proudest achievements is co-authoring a publication in Human Pathology. In this project, 
            I applied my data science skills using Python and R to create graphs and charts from the given data, 
            which helped investigate TRPS1 expression across various carcinomas. 
            This experience has honed my skills in data visualization and statistical analysis.
          </p>
          <p>
            I am currently seeking job opportunities where I can apply my skills and knowledge in a dynamic and challenging environment. 
            I am particularly interested in roles related to software development, data analysis, and web development. 
            My goal is to contribute to innovative projects that make a positive impact.
          </p>
          <p>
            Feel free to reach out to me via email at shen.zhang0907@gmail.com or connect with me on LinkedIn (<a href="https://www.linkedin.com/in/shen-zhang-b889002b3/" target="blank">https://www.linkedin.com/in/shen-zhang-b889002b3/</a>). 
            I am excited to explore new opportunities and connect with like-minded professionals.
          </p>
        </div>
        <!-- More content sections -->
      </section>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'App',
  data() {
    return {
      
      isScrolled: false,
      showContactSection: false,  // Control the display of the contact section
      isMobile: window.innerWidth <= 768
    };
  },
  created() {
    // Using lodash to throttle the scroll event handler
    window.addEventListener('scroll', _.throttle(this.handleScroll, 200));
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    updateLayout() {
      if (window.innerWidth > 768) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    toggleContactSection() {
      this.showContactSection = !this.showContactSection;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },

    handleScroll() {
      this.isScrolled = window.scrollY > 50; // Adjust 50 to the threshold after which the header should shrink
    },

    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        const headerOffset = 95; 
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  }
  
}
</script>

<style>

/* General Body Styles */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background-color: white;
  color: #333;
}

/* Header Styles */
header {
  transition: height 0.5s ease;
  height: 100vh; /* Full screen by default */
  background-image: url('@/assets/bg7.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center; /* Align content vertically */
  justify-content: center; /* Align content horizontally */
  text-align: center; /* Ensure text is centered */
  color: white; /* Text color */
}
/* Style for when the header is resized after scrolling */
.header-small {
  height: 300px; /* Default height after scroll */
}

.header-content h1 {
  
  font-size: 3rem; /* Large size for visibility */
  margin: 0.5rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  
}

.extra-content {
  height: 1300px; /* Ensure this is enough to make the page scrollable */
}

/* Navigation Styles */
nav {
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;
}

/* Profile Section Styles */
.profile {

  width: 40%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 250px; /* Adjust based on your design */
  background-color: rgba(0, 0, 0, 0.5); /* Dark with 50% opacity */
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px;
  transition: transform 0.4s ease-in-out;
  
}

.profile:hover {
  transform: translateY(-10px);
}

.profile-image {
  width: 175px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid white;
  transition: all 0.3s ease-in-out;
}

.profile-image:hover {
  border-color: #000000;
  
}



/* Container Styles */
.container {
  min-height: 150vh;
  width: 100%;
  margin: auto;
  padding-top: 55px;
  overflow: hidden;
  height:  auto;
  position: relative;
  
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for depth */
  color: #ffffff; 
  font-size: 18px; 
  background-image: url('@/assets/bg10.jpg'); 
  background-size: cover; /* Cover ensures the background covers the entire container */
  background-position: center; /* Centers the background image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(209, 192, 192, 0.5); /* Black overlay with 50% opacity */
  z-index: 1;
}

.content-block {
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  padding: 20px;
  margin: 15px 0; /* Adds space between content blocks */
  border-radius: 10px; /* Optional rounded corners */
  color: #FFFFFF; /* White text for better readability */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
}


/* Make sure the content is on top of the overlay */
.container > * {
  position: relative;
  z-index: 2;
}

/* Button Styles */
button {
  background-color: #9f04049f;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #c2302d;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* General Section Styles */
section {
  width: 40%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 300px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Dark with 50% opacity */
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.8s ease;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.hidden-section {
  background-color: rgba(0, 0, 0, 0.5); /* Slightly darker for visibility */
  color: white;
  padding: 20px;
  width: 40%;
  margin: auto; /* Center and add margin */
  border-radius: 10px; /* Consistent styling with other sections */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.navbar {
  width: 100%;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
  z-index: 1000; /* Ensure navbar is above other content */
  background-color: transparent;
}

.navbar-scrolled {
  background-color: rgba(0, 0, 0, 0.8); /* Darken navbar on scroll */
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: center; /* Center links horizontally */
  padding: 0;
  
}

.navbar li {
  margin: 0 15px;
  text-align: center;
  padding: 10px;
}

.navbar a {
  text-decoration: none;
  color: white; /* White text color */
  font-weight: bold;
  transition: color 0.3s ease;
}

.navbar a:hover {
  color: #f44336; /* Red color on hover */
}

.hidden_header {
  display: none;
}

.container {
  transition: margin-top 0.3s ease;
}
.container.scrolled {
  margin-top: -200px 
}

@media (max-width: 768px) {
  .header {
    align-items: center; /* Align content vertically */
    justify-content: center; /* Align content horizontally */
  }
  
  .header-content h1 {
    font-size: 1.5rem; /* Larger font size for readability */
    
  }
  .navbar ul {
    flex-direction: row; /* Stack nav items vertically on smaller screens */
    align-items: center;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
  }
  .navbar li {
    width: 100%;
    text-align: center; /* Center text for better readability */
    margin-bottom: 10px; /* Add space between menu items */
  }
  .profile {
    width: 90%; /* Increase the width on mobile */
    margin: 20px auto; /* Center it with automatic horizontal margins */
    padding: 20px; /* Adjust padding if necessary */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    display: block; /* Stack the layout vertically */
    text-align: center; /* Center the text */
    
  }
  .profile-image {
    
    margin-bottom: 20px; /* Space between image and text */
  }

  section {
    width: 80%; /* Make sections wider on mobile */
    margin: 20px auto; /* Add more space top and bottom, center horizontally */
    padding: 20px; /* Adequate padding for readability */
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Optional: adds subtle shadow for depth */
  }

  .container {
    width: 100%;
    overflow: visible; /* Ensure overflow is visible */
    margin: auto;
  }

  .content, .hidden-section {
    width: 80%;
    padding: 15px; /* Increase padding for visibility */
  }

}



</style>

